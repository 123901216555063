import styled from 'styled-components';
import { SlideBanner } from '@components/common';

export const Wrapper = styled.div``;

export const SlideGroup = styled(SlideBanner)`
  margin-bottom: 90px;
  box-sizing: content-box;
`;

export const HoverBannerGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  padding-top: 22px;
`;
