import React from 'react';
import { useTranslation } from 'next-i18next';
import mainCardList from 'public/mainCardList.json';
import { AdminPickCardGroup, SnoCardGroup } from '@components/common';
import { BANNER_INTERNAL_NAME, BEST_INTERNAL_NAME, FIXED_BANNER_INTERNAL_NAME, GOODS_INTERNAL_NAME } from 'src/constants';
import { ImageBanner } from '@components/common/ImageBanner';
import { convertLangCodeForDB } from '@lib';
import { useMainProductListV2Query } from '../../../src/generated/graphql';
import { SlideGroup, Wrapper } from './styled';

type Widget = {
  sno: number;
  type: 'goods' | 'banner' | 'fixedBanner' | 'best';
  linkUrl?: string;
  imageUrl?: string;
  isDisplayRankBadge?: boolean;
};

export const MainWidget = (): React.ReactElement => {
  const { i18n, ready } = useTranslation('common');

  // 주간 베스트 타이틀 가져와야 해서...
  const { data, loading } = useMainProductListV2Query({
    variables: {
      lang: convertLangCodeForDB(i18n.language),
      sno: 24,
    },
  });
  if (!ready || loading) return <div></div>;
  const AdminPickTitle = data?.mainProductListV2?.title || '';

  return <Wrapper>
    {
      // JSON 데이터 기반으로 메인 위젯을 리턴
      (mainCardList[i18n.language] ?? []).map((cardInfo: Widget, idx: number) => {
        switch (cardInfo && cardInfo.type) {
          case GOODS_INTERNAL_NAME:
            return <SnoCardGroup key={idx} sno={cardInfo.sno} isDisplayRankBadge={cardInfo.isDisplayRankBadge} eventPageName={'main'} />;
          case BANNER_INTERNAL_NAME:
            return <SlideGroup key={idx} bannerCode={cardInfo.sno} />;
          case FIXED_BANNER_INTERNAL_NAME:
            return (
              <div key={idx} data-mixpanel-action="click" data-mixpanel-evt="Click Banner" data-mixpanel-type="Fixed Banner" data-mixpanel-link={cardInfo.linkUrl}>
                <ImageBanner imageUrl={cardInfo.imageUrl} linkUrl={cardInfo.linkUrl} imageHeight={120} imageWidth={900} style={{ marginBottom: '90px' }} />
              </div>
            );
          case BEST_INTERNAL_NAME:
            return <AdminPickCardGroup key={idx} title={AdminPickTitle} isDisplayRankBadge={cardInfo.isDisplayRankBadge} />;
        }
        return null;
      })
    }
  </Wrapper>;
};
