export const PRODUCTS_TYPE = {
  CATEGORY: 'category',
  BRAND: 'brand',
  EXHIBITION: 'exhibition',
  SEARCH: 'search',
  COSMIC_DEAL: 'cosmic-deal',
  COMET_DEAL: 'comet-deal',
};

export const PACKAGE_TYPE = {
  PARENT: 'parent',
  CHILDREN: 'children',
};
